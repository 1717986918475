import React from 'react'
import Loading from '../components/Program/Loading'
import { useExternalForm } from '../utils/customHooks'
import { gaTrack } from '../utils/analytics-tracking'
import { NoIndex } from '../components/NoIndex'

const B2BContentRequestPage = ({ location }) => {
  const { loaded } = useExternalForm({
    portalId: '7420342',
    formId: 'b61a66e2-6e5e-4605-b940-573349c56e8c',
    target: '#partner-hubspot-form',
    onFormSubmitted: () => {
      gaTrack({
        category: 'B2B Contact',
        action: 'Submit',
        label: 'Content'
      })
    }
  })

  return (
    <>
      <NoIndex />
      <div className="flex flex-row pt-6 pb-6 items-center justify-center text-center gp-brand">
        <div className="flex flex-col max-w-3xl px-4">
          <h1 className="text-3xl text-gp-violet">
            Care differently with Goodpath
          </h1>
          <p className="pt-2 leading-relaxed text-gp-grey-100">
            Access Goodpath's thought-leadership on MSK, sleep, and digestive
            health issues. It is freely available to all healthcare providers,
            insurers, employers, and other stakeholders as part of Goodpath's
            mission to improve member satisfaction and quality of life.
          </p>
        </div>
      </div>
      {!loaded && <Loading />}
      <div className="flex flex-row justify-center items-center p-2">
        <div className="w-full" id="partner-hubspot-form" />
      </div>
    </>
  )
}

export default B2BContentRequestPage
